/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 18px;
  font-family: "Indie Flower", Helvetica, sans-serif;
  font-weight: 800;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

.bg-footer {
  background-color: rgba(0, 0, 0, 0.25);
}

#gradient-canvas {
  width: 100vw;
  height: 100vh;
  --gradient-color-1: #7b0967;
  --gradient-color-2: #421a92;
  --gradient-color-3: #404be3;
  --gradient-color-4: #06aedb;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
}
